import React from "react";
import PropTypes from "prop-types";
import "../../index.css";
import "./release_notes.css";
import { VERSION } from "../../helpers";

const NEW = [
  "Added DoubleFree SymbEx analysis. Detects instances of CWE-415: Double Free.",
  "Added NullPointerDereference SymbEx analysis. Detects instances of CWE-476: NULL Pointer Dereference.",
  "Added OutOfBoundsRead SymbEx analysis. Detects instances of CWE-125: Out-of-bounds Read.",
  "Added OutOfBoundsWrite SymbEx analysis. Detects instances of CWE-787: Out-of-bounds Write.",
  "Vulnerabilities detected using SymbEx now display the program's function call stack at the time the vulnerability was encountered.",
];
const UPDATED = [
  "The Input to Reproduce section displayed for vulnerabilities detected using SymbEx now reports input read from file and input read over the network (e.g., TCP/UDP packets).",
  "'Duplicate' vulnerabilities are now reported significantly less often.",
  "Fixed various minor bugs and performance issues.",
];
const REMOVED = ["None."];

/**
 * Release notes modal that appears on the login page.
 * Displays what has been added, updated, and removed from this
 * release version.
 */
export default class ReleaseNotesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "NEW", // | UPDATED | REMOVED
    };
  }

  render() {
    return (
      <div className="release-notes-div">
        <h2 className="version-number">{VERSION}</h2>
        <div className="row">
          <h4
            onClick={() => this.setState({ page: "NEW" })}
            className={this.state.page === "NEW" ? "selected-rel" : "rele"}
          >
            New
          </h4>
          <h4
            onClick={() => this.setState({ page: "UPDATED" })}
            className={this.state.page === "UPDATED" ? "selected-rel" : "rele"}
          >
            Updated
          </h4>
          <h4
            onClick={() => this.setState({ page: "REMOVED" })}
            className={this.state.page === "REMOVED" ? "selected-rel" : "rele"}
          >
            Removed
          </h4>
        </div>
        {this.state.page === "NEW" && (
          <ul className="">
            {NEW.map((n) => (
              <li>{n}</li>
            ))}
          </ul>
        )}
        {this.state.page === "UPDATED" && (
          <ul>
            {UPDATED.map((u) => (
              <li>{u}</li>
            ))}
          </ul>
        )}
        {this.state.page === "REMOVED" && (
          <ul>
            {REMOVED.map((r) => (
              <li>{r}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

ReleaseNotesModal.propTypes = {
  /**
   * Callback that occurs when the user attempts to close the release
   * notes modal.
   */
  pressFunction: PropTypes.func.isRequired,
};
