/*
    This component represents the total assessment progress for a 
    given asset that is currently under analysis. It displays an 
    estimated time of completion and number of assessments completed 
    out of the total. 
    
    Its order in relation to other <AnalysisQueue_Asset /> components is 
    determined based on the priority of its child assessments. This component 
    contains a delete button that deletes the asset and all associated binary 
    assessments. This component also contains a list of child <AnalysisQueue_Binary /> 
    components, which are defined below. There is a button to upload a new 
    binary for assessment to this ongoing asset analysis.
*/
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { reprioritizeBinary, stopAsset } from "../../../api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Tooltip } from "react-tippy";

// components
import AnalysisQueue_Binary from "./binary.jsx";

// icons
import { Trash } from "react-feather";

export default function AnalysisQueue_Asset({
  asset,
  setAssets,
  stallRefresh,
  onDragStart,
  onDragEnd,
}) {
  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.pointerEvents = "none";
      div.style.top = "0";
      div.style.width = "100%";
      div.style.height = "100%";
      self.elt = div;
      document.body.appendChild(div);
      return () => {
        document.body.removeChild(div);
      };
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };
  const renderDraggable = useDraggableInPortal();

  return (
    <div
      className={
        asset.state === "RUNNING"
          ? "analysis-queue-asset analysis-queue-asset-started"
          : "analysis-queue-asset"
      }
      style={{
        borderBottomWidth: asset.state === "RUNNING" ? 8 : 2,
      }}
    >
      <div className="asset-analysis-button-row">
        <Tooltip
          // options
          title={asset.name}
          position="bottom"
          trigger="mouseenter"
          arrow
          size="small"
          className="asset-analysis-name-tooltip"
        >
          <h3
            style={{
              lineHeight: 1.4,
            }}
          >
            <b
              style={{
                color: "var(--pri-color)",
                fontSize: 18,
                marginRight: 10,
              }}
            >
              {asset.name}
            </b>
          </h3>
        </Tooltip>

        <button
          className="sec-button"
          onClick={async () => {
            if (window.confirm("Are you sure you want to delete this asset?")) {
              await stopAsset(asset.org_id, asset.asset_id);
              stallRefresh();
            }
          }}
        >
          Delete Asset
          <Trash
            className="icon"
            style={{
              width: 14,
              height: 14,
              marginLeft: 10,
            }}
          />
        </button>
      </div>

      <DragDropContext
        onDragStart={() => {
          onDragStart();
        }}
        onDragEnd={async (result) => {
          onDragEnd();
          if (!result.destination) return;
          let old_bins = [...asset.binaries];

          // update state list
          setAssets((assets) => {
            let new_assets = [...assets];
            let my_asset = new_assets.filter(
              (a) => a.asset_id === asset.asset_id
            )[0];

            let new_bins = [...my_asset.binaries];
            let binary = new_bins.filter(
              (b) => b.bin_id === result.draggableId
            )[0];
            new_bins = new_bins.filter((b) => b.bin_id !== result.draggableId);
            new_bins.splice(result.destination.index, 0, binary);
            my_asset.binaries = new_bins;

            return new_assets;
          });

          // determine if we went up or down the list
          let diff = result.destination.index - result.source.index;
          let other_bin = old_bins[result.destination.index];
          if (diff > 0) {
            // we moved down the list
            await reprioritizeBinary(
              asset.org_id,
              asset.asset_id,
              result.draggableId,
              asset.org_id,
              asset.asset_id,
              other_bin.bin_id,
              false
            );
          } else if (diff < 0) {
            // we moved up the list
            await reprioritizeBinary(
              asset.org_id,
              asset.asset_id,
              result.draggableId,
              asset.org_id,
              asset.asset_id,
              other_bin.bin_id,
              true
            );
          }

          await stallRefresh();
        }}
      >
        <Droppable droppableId="droppable" style={{ overflow: "scroll" }}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {asset.state === "RUNNING" &&
                asset.binaries.map((binary, index) => {
                  return (
                    <Draggable
                      className="report-builder-component"
                      draggableId={binary.bin_id}
                      index={index}
                      key={binary.bin_id}
                    >
                      {renderDraggable((provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          <AnalysisQueue_Binary
                            binary={binary}
                            org_id={asset.org_id}
                            asset_id={asset.asset_id}
                            refresh={stallRefresh}
                          />

                          {provided.placeholder}
                        </div>
                      ))}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {asset.state === "PENDING" && (
        <h3 className="num-bins-more">
          {asset.num_bins} {asset.num_bins == 1 ? "binary" : "binaries"}{" "}
          awaiting assessment
        </h3>
      )}
    </div>
  );
}
