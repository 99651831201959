/*
    Parent component of the rest of the related queue components. 
    Has two sides: one side shows an ordered list of assessments 
    (ordered by priority), the other side shows the progress of 
    individual assets and binaries.
*/
import { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "./analysis_queue.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// components
import AnalysisQueue_Asset from "./asset";
import AnalysisQueue_Assessment from "./assessment";
import { reprioritizeAssessment, reprioritizeAsset } from "../../../api";

export default function AnalysisQueue({
  analyses,
  refresh,
  stalled,
  stallRefresh,
  pinned,
  setPinned,
  onDragStart,
  onDragEnd,
}) {
  let [queue, setQueue] = useState(analyses.assessments);
  let [assets, setAssets] = useState(analyses.assets);

  // update ghost queue
  useEffect(() => {
    setQueue(analyses.assessments);
    setAssets(analyses.assets);
  }, [analyses]);

  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.pointerEvents = "none";
      div.style.top = "0";
      div.style.width = "100%";
      div.style.height = "100%";
      self.elt = div;
      document.body.appendChild(div);
      return () => {
        document.body.removeChild(div);
      };
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };
  const renderDraggable = useDraggableInPortal();

  return (
    <div className="analysis-queue">
      <div className="analysis-queue-left">
        {assets.length > 0 && (
          <AnalysisQueue_Asset
            asset={assets[0]}
            setAssets={setAssets}
            refresh={refresh}
            stallRefresh={stallRefresh}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        )}

        <DragDropContext
          onDragStart={() => {
            onDragStart();
          }}
          onDragEnd={async (result) => {
            onDragEnd();
            if (!result.destination) return;

            // update state list
            let new_assets = [...assets];
            let asset = new_assets.filter(
              (a) => a.asset_id === result.draggableId
            )[0];
            new_assets = new_assets.filter(
              (a) => a.asset_id !== result.draggableId
            );
            new_assets.splice(result.destination.index + 1, 0, asset);
            setAssets(new_assets);

            // we moved down the list
            await reprioritizeAsset(
              asset.org_id,
              asset.asset_id,
              result.destination.index + 1
            );
          }}
        >
          <Droppable droppableId="droppable" style={{ overflow: "scroll" }}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {assets.slice(1).map((asset, index) => {
                  return (
                    <Draggable
                      className="report-builder-component"
                      draggableId={asset.asset_id}
                      index={index}
                      key={asset.asset_id}
                      isDragDisabled={asset.state === "RUNNING"}
                    >
                      {renderDraggable((provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          <AnalysisQueue_Asset
                            asset={asset}
                            setAssets={setAssets}
                            refresh={refresh}
                            stallRefresh={stallRefresh}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                          />

                          {provided.placeholder}
                        </div>
                      ))}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="analysis-queue-right">
        {stalled && (
          <div className="queue-stalled-cover">
            <h3>Assessments are being reprioritized.</h3>
          </div>
        )}

        <div className="analysis-queue-right-header">
          <svg
            onClick={(e) => {
              e.stopPropagation();
              setPinned(!pinned);
            }}
            className="icon"
            stroke={pinned ? "var(--sec-color)" : "var(--pri-color-light)"}
            fill={pinned ? "var(--sec-color)" : "var(--pri-color-light)"}
            style={{
              outline: pinned ? "2px solid var(--sec-color)" : "none",
            }}
            stroke-width="0"
            viewBox="0 0 1024 1024"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3-15.4 12.3-16.6 35.4-2.7 49.4l181.7 181.7-215.4 215.2a15.8 15.8 0 0 0-4.6 9.8l-3.4 37.2c-.9 9.4 6.6 17.4 15.9 17.4.5 0 1 0 1.5-.1l37.2-3.4c3.7-.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8z"></path>
          </svg>
        </div>

        <div
          className="analysis-queue-right-scroller"
          style={{
            pointerEvents: stalled ? "none" : "all",
          }}
        >
          <DragDropContext
            onDragStart={() => {
              onDragStart();
            }}
            onDragEnd={async (result) => {
              onDragEnd();
              if (!result.destination) return;
              let ids = JSON.parse(result.draggableId);
              // update state queue first
              let new_queue = [...queue];
              let assessment = new_queue.filter(
                (a) =>
                  a.id.asset_id === ids.asset_id &&
                  a.id.bin_id === ids.bin_id &&
                  a.id.tool_id === ids.tool_id
              )[0];
              new_queue = new_queue.filter(
                (a) =>
                  a.id.asset_id !== ids.asset_id ||
                  a.id.bin_id !== ids.bin_id ||
                  a.id.tool_id !== ids.tool_id
              );
              new_queue.splice(result.destination.index, 0, assessment);
              setQueue(new_queue);

              // propagate the change to the backend
              await reprioritizeAssessment(
                ids.org_id,
                ids.asset_id,
                ids.bin_id,
                ids.tool_id,
                result.destination.index
              );
            }}
          >
            <Droppable droppableId="droppable" style={{ overflow: "scroll" }}>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {queue.map((assessment, index) => {
                    let id = JSON.stringify({
                      org_id: assessment.id.org_id,
                      asset_id: assessment.id.asset_id,
                      bin_id: assessment.id.bin_id,
                      tool_id: assessment.id.tool_id,
                    });

                    return (
                      <Draggable
                        className="report-builder-component"
                        draggableId={id}
                        index={index}
                        key={id}
                      >
                        {renderDraggable((provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <AnalysisQueue_Assessment
                              assessmentProp={assessment}
                              priority={index}
                              refresh={refresh}
                            />

                            {provided.placeholder}
                          </div>
                        ))}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {queue.length >= 100 && (
            <h3
              style={{
                alignSelf: "center",
                margin: 20,
              }}
            >
              Only the top 100 assessments are shown.
            </h3>
          )}
        </div>
      </div>
    </div>
  );
}
