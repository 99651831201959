import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ASSET_TYPES } from "../../asset_types";

// icons
import { MoreHorizontal } from "react-feather";

// the number of assets to display before toggling long-form on
const STARTING_NUM_OF_ASSETS = 6;

/**
 * Section of the FileDrop component that lets the user
 * select the new Asset's type.
 * Can be toggle between short-form and long-form.
 * Long-form displays additional asset-type options.
 */
export default function AssetTypeSelector({ selectType, selectedType }) {
  let [dropdown, setDropdown] = useState(false);

  // hide downdown onClick
  useEffect(() => {
    const hideDropdown = () => setDropdown(false);
    window.addEventListener("click", hideDropdown);
    return () => window.removeEventListener("click", hideDropdown);
  });

  // only display some of the types if the dropdown isn't on
  let types = dropdown
    ? [...ASSET_TYPES]
    : [...ASSET_TYPES].splice(0, STARTING_NUM_OF_ASSETS);
  if (!dropdown && selectedType && !types.includes(selectedType)) {
    types.pop();
    types.push(selectedType);
  }

  return (
    <div className="asset-type-selector">
      <hr className="new-analysis-line"></hr>
      <h3 className="new-analysis-question">What kind of asset?</h3>
      <div
        className="new-analysis-base-row"
        style={{
          marginTop: 10,
          flexWrap: "wrap",
          rowGap: 10,
          position: dropdown ? "absolute" : "static",
          background: dropdown ? "var(--bg-color)" : "transparent",
          paddingLeft: dropdown ? 20 : 0,
          paddingRight: dropdown ? 20 : 0,
          paddingBottom: dropdown ? 20 : 0,
          transform: dropdown ? "translateX(-20px)" : "none",
          borderBottomLeftRadius: dropdown ? 20 : 0,
          overflowY: dropdown ? "visible" : "hidden",
          height: dropdown ? 350 : "fit-content",
          alignContent: "flex-start",
          zIndex: 2,
        }}
      >
        {types.map((type) => {
          return (
            <div
              className={
                selectedType === type
                  ? "new-analysis-option-selected"
                  : "new-analysis-option"
              }
              onClick={() => {
                selectType(type);
                setDropdown(false);
              }}
            >
              {type}
            </div>
          );
        })}

        {!dropdown && (
          <MoreHorizontal
            onClick={(e) => {
              e.stopPropagation();
              setDropdown(true);
            }}
            className="more-asset-types"
          />
        )}
      </div>
    </div>
  );
}

AssetTypeSelector.propTypes = {
  /**
   * Callback function that selects on of the asset types.
   */
  selectType: PropTypes.func.isRequired,

  /**
   * The current selected type. This type is highlighted in
   * blue.
   *
   */
  selectedType: PropTypes.string.isRequired,
};
